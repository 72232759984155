@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Public+Sans:wght@400;700&display=swap');

body {
    background-color: #FFEDD0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: zoom 1s;
    zoom: 80%;
}

html {
    overflow-x: hidden;
}


@media (max-height: 700px) {
    body {
        zoom: 60%;
    }
}


@media (max-height: 480px) {
    body {
        zoom: 50%;
    }
}

@media (max-height: 400px) {
    body {
        zoom: 35%;
    }
}

@media (min-width: 1500px) and (min-height: 900px) {
    body {
        zoom: 100%;
        /* opacity: 0.3; */
    }
}

.ai-image-generator {
    overflow-y:auto
}

.introAnimationContainer.preIntro {
    /* background-color: #FFEDD0; */
    /* background-color: red; */
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 40;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    transition: opacity 1s, visibility 0s 1s;
    visibility: visible;
}

.introAnimationContainer.preIntro .introBackground {
    position: absolute;
    background: #FEA5DD;
    height: 100%;
    width: 100%;
    clip-path: inset(0% 0 0% 0);
    transition: clip-path 1s;
    z-index: 10;
}

.introAnimationContainer.start {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}


.introHeader {
    width: 500px;
    color: #353535;
    font-family: Public Sans;
    font-size: 53.844px;
    font-style: normal;
    font-weight: 700;
    line-height: 110.772%; /* 59.645px */
    position: absolute;
    top: 180px;
    z-index: 11;
    transition: transform 0.6s 0.4s cubic-bezier(.46,-0.01,0,1), clip-path 0.6s 0.4s cubic-bezier(.46,-0.01,0,1);
}

.bigTextContainer {  
    position: absolute;
    top: 300px;
    width: 820px;
    display: inline-flex;
    align-items: flex-start;
    padding-right: 100px;
    z-index: 80;
    /* background-color: red; */
}


.introBigText {
    z-index: 80;
    color: #353535;
    font-family: Dela Gothic One;
    font-size: 231.56px;
    font-style: normal;
    font-weight: 400;
    line-height: 73.124%; /* 169.326px */
    transition: transform 0.6s 0.7s cubic-bezier(.33,.02,0,1.44), clip-path 0.3s 0.7s cubic-bezier(.46,-0.01,0,1);
}

.introBigText.p {
    transition-delay: 0.5s;
}

.introBigText.l {
    transition-delay: 0.6s;
}

.introBigText.a {
    transition-delay: 0.8s;
}

.introBigText.y {
    transition-delay: 1.1s;
}


.preIntro .introHeader {
    clip-path: inset(-100px 0% 100% 0px);
    transform: translate(-200px, 150px);
}

.preIntro .introBigText {
    clip-path: inset(-100px 0% 100% 0px);
    transform: translateY(250px) rotate(10deg);
}

.intro .introHeader {
    clip-path: inset(-100px 0% -10% 0px);
    transform: translateX(-200px);
}

.intro .introBigText {
    clip-path: inset(-100px 0% -10% 0px);
    transform: none;
}

.penContainer.preIntro {
    z-index: 100;
    transition: transform 1.4s 0.3s cubic-bezier(.46,-0.01,0,1);
    transform: translate(0px, 390px) rotate(90deg);
}

.penContainer.preIntro .penBody {
    transform: translateX(-300px);
}

.penContainer.preIntro .penCap {
    visibility: visible;
    transform: translate(-160px);
}

.introBody {
    color: #353535;
    font-family: Public Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 700px;
    /* margin-right: 400px; */
    transition: opacity 0.4s 1.7s, transform 0.4s 1.7s;
    z-index: 60;
}

.introAnimationContainer.preIntro .introBody {
    transform: translate(-100px, 630px);
    opacity: 0;
}
.introAnimationContainer.intro .introBody {
    transform: translate(-100px, 520px);
    opacity: 1;
}

.introAnimationContainer.preIntro .buttonContainer {
    position: absolute;
    top: 690px;
    opacity: 0;
    transition: opacity 0.5s 3.5s, top 0.5s 3.5s;
    z-index: 12;
}

.introAnimationContainer.intro .buttonContainer {
    top: 650px;
    opacity: 1;
}

.introAnimationContainer.preIntro .generate-btn {
    height: 60px;
}

.introAnimationContainer.intro .generate-btn {
    /* transition: opacity 0.2s linear, transform 0.3s, box-shadow 0.3s; */
}

.introAnimationContainer.preIntro .loadingText {
    color: white;
    color: #FFF;
    text-align: center;
    font-family: Public Sans;
    font-size: 29.468px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.772%; /* 32.642px */
    z-index: 60;
    position: absolute;
    top: 630px;
    opacity: 1;
    transition: opacity 1s;
 }

.introAnimationContainer.intro .loadingText {
    opacity: 0;
 }

.introAnimationContainer.preIntro .loadingBackground {
    position: absolute;
    background: #39C797;
    height: 100%;
    width: 100%;
    clip-path: inset(0% 0 0% 0);
    transition: clip-path 1s;
    z-index: 20;
}

.introAnimationContainer.intro .loadingBackground {
    clip-path: inset(100% 0 0% 0);
}

.penContainer.intro {
    transform: translate(410px, -20px) rotate(-90deg) scale(0.6);
}
.penContainer .penCap {
    transition: transform 1s;
}
.penContainer.intro .penCap {
    transform: translate(-160px);
    transition: transform 0.3s 2.5s cubic-bezier(.83,-0.05,.19,1.3);
}

.penContainer.intro .penCap {
    transform: translate(-180px);
}

.penContainer .bow {
    position: absolute;
    left: -200px;
    transform: rotate(-90deg) translate(35px) scaleX(-1);
    opacity: 1;
    transition: opacity 0.5s;
}

.penContainer.intro .bow {
    opacity: 0;
}

.penContainer.intro .penBody {
    transform: translate(-120px);
}

.penBody {
    transition: transform 0.3s 2.5s cubic-bezier(.83,-0.05,.19,1.3);
}




.borderShapesContainer {
}
.borderShapesContainer img{
    position: absolute;
    transition: transform 0.8s;
}

.borderShapesContainer.intro {
    z-index: 50;
}

.borderShapesContainer.start {
    transition: z-index 0s 2s;
    z-index: -20;
}

.borderShapesContainer img , .header, .generate-btn{
/* If you want to implement it in very old browser-versions */
-webkit-user-select: none; /* Chrome/Safari */ 
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+ */

/* The rule below is not implemented in browsers yet */
-o-user-select: none;

/* The rule below is implemented in most browsers by now */
user-select: none;
}

.borderShape1 {
    pointer-events: none;
    top: 547px;
    right: -420px;
}
.borderShape2 {
    top: -58px;
    right: -98px;
}
.borderShape6 {
    top: 195px;
    right: 43px;
}
.borderShape3 {
    top: 48px;
    left: -38px;
}
.borderShape4 {
    top: 766px;
    left: -209px;
}
.borderShape5 {
    top: 366px;
    left: -60px;
}

.borderShapesContainer.preIntro .borderShape1 {
    transform: translate(450px, 100px) scale(0.6) rotate(-100deg); transition: transform 0.6s 0.02s;
}

.borderShapesContainer.preIntro .borderShape2 {
transform: translate(90px, -280px) scale(0.7) rotate(-100deg);
}

.borderShapesContainer.preIntro .borderShape6 {
transform: translate(300px, -10px) scale(0.5) rotate(-100deg);
}
.borderShapesContainer.preIntro .borderShape3 {
transform: translate(-200px, -100px) scale(0.7) rotate(-100deg); transition: transform 0.6s 0.02s;
}
.borderShapesContainer.preIntro .borderShape4 {
transform: translate(-400px,100px) scale(0.7) rotate(-100deg);
}

.borderShapesContainer.preIntro .borderShape5 {
transform: translate(-200px, 0px)  scale(0.7) rotate(-100deg);
}


.borderShapesContainer.intro .borderShape1 {
transform: translate(0px, -100px) scale(0.6); transition: transform 2s 0.02s;
}

.borderShapesContainer.intro .borderShape2 {
transform: translate(0px, -80px) scale(0.7); 
}

.borderShapesContainer.intro .borderShape6 {
transform: translate(30px, -10px) scale(0.7);
}
.borderShapesContainer.intro .borderShape3 {
transform: translate(-100px, -90px) rotate(20deg)scale(0.7); transition: transform 0.6s 0.02s;
}
.borderShapesContainer.intro .borderShape4 {
transform: translate(0px,60px) scale(0.7);
}

.borderShapesContainer.intro .borderShape5 {
transform: translate(-00px, 0px)  scale(0.7);
}

.borderShapesContainer.start .borderShape1, 
    .borderShapesContainer.start .borderShape2, 
    .borderShapesContainer.start .borderShape6, 
    .borderShapesContainer.start .borderShape3, 
    .borderShapesContainer.start .borderShape4, 
    .borderShapesContainer.start .borderShape5 {
        transform: none;
    }


.borderShapesContainer.loading .borderShape1 {
    transform: translate(-100px, 100px) rotate(-8000deg);
    transition: transform 120s 0.1s;
}
.borderShapesContainer.loading .borderShape2 {
    transform: rotate(4000deg);
    transition: transform 120s 0.1s;
}
.borderShapesContainer.loading .borderShape6 {
    transform: translate(-50px, 10px) rotate(-4000deg);
    transition: transform 120s 0.1s;
}
.borderShapesContainer.loading .borderShape3 {
    transform: translate(100px, 0px) rotate(18334deg);
    transition: transform 120s 0.05s;
}
.borderShapesContainer.loading .borderShape4 {
    transform: translate(60px, 0px) rotate(6314deg);
    transition: transform 120s 0.02s;
}
.borderShapesContainer.loading .borderShape5 {
    transform: translate(30px, 0px) rotate(-2234deg);
    transition: transform 120s 0.02s;
}
.borderShapesContainer.imageCreated .borderShape1, 
    .borderShapesContainer.imageCreated .borderShape2, 
    .borderShapesContainer.imageCreated .borderShape6, 
    .borderShapesContainer.imageCreated .borderShape3, 
    .borderShapesContainer.imageCreated .borderShape4, 
    .borderShapesContainer.imageCreated .borderShape5 {
        transform: none;
    }

.borderShapesContainer.animateLoading .borderShape1 {
    transform: translate(-100px, 100px) rotate(-30deg);
    transition: transform 80s 0.1s;
}
.borderShapesContainer.animateLoading .borderShape2 {
    transform: rotate(20deg);
    transition: transform 0.9s 0.1s;
}
.borderShapesContainer.animateLoading .borderShape6 {
    transform: translate(-50px, 10px);
    transition: transform 0.8s;
}
.borderShapesContainer.animateLoading .borderShape3 {
    transform: translate(100px, 0px) rotate(34deg);
    transition: transform 80s 0.05s;
}
.borderShapesContainer.animateLoading .borderShape4 {
    transform: translate(60px, 0px) rotate(14deg);
    transition: transform 0.75s 0.02s;
}
.borderShapesContainer.animateLoading .borderShape5 {
    transform: translate(30px, 0px) rotate(-14deg);
    transition: transform 0.6s 0.02s;
}

.borderShapesContainer.imageCreated .borderShape1 {
    transform: rotate(-120deg);
}
.borderShapesContainer.imageCreated .borderShape2 {
    transform: translate(0px, -80px) rotate(180deg);
}
.borderShapesContainer.imageCreated .borderShape6 {
    transform: translate(20px, -10px);
}
.borderShapesContainer.imageCreated .borderShape3 {
    transform: rotate(204deg);
}
.borderShapesContainer.imageCreated .borderShape4 {
    transform: translate(0px,60px) rotate(-180deg);
}
.borderShapesContainer.imageCreated .borderShape5 {
    transform: translate(0px, 0px) rotate(0deg);
}

.borderShapesContainer.uploading .borderShape1 {
    transform: translate(50px, 0px) rotate(-130deg) scale(0.7);
    transition: transform 0.6s 0.02s;
}
.borderShapesContainer.uploading .borderShape2 {
    transform: translate(0px, -80px) rotate(180deg) scale(0.7);
}
.borderShapesContainer.uploading .borderShape6 {
    transform: translate(20px, -10px) scale(0.7);
}
.borderShapesContainer.uploading .borderShape3 {
    transform: rotate(204deg) scale(0.7);
    transition: transform 0.6s 0.02s;
}
.borderShapesContainer.uploading .borderShape4 {
    transform: translate(0px,60px) rotate(-180deg) scale(0.7);
}
.borderShapesContainer.uploading .borderShape5 {
    transform: translate(0px, 0px) rotate(0deg) scale(0.7);
}


.penContainer{
    position: absolute;
    top: 200px;
    z-index: 20;
    pointer-events: none;
}

.penContainer.start {
    transform: translate(220px) rotate(-50deg);
    transition: transform 1s;
}


.penContainer.drawingLoop {
    transform: translate(120px, -50px) rotate(-40deg);
}

.penContainer.animateLoading {
    transform: translate(220px) rotate(-50deg);
}

.penContainer.start .penBody {
    transition: transform 1s;
    transform: none;
}

.penBody:hover {
    transform: rotate(5deg);
}

.penContainer {
    /* background: rgba(0, 0, 0, 0.05); */
}

.penContainer.loading .penBody {
    transform: translate(90px, -90px) rotate(-20deg);
    transition: transform 0.6s;
}

.penContainer.start .penCap {
    visibility: hidden;
    transform: translateX(140px);
}

.penContainer.animateLoading .penBody {
    transform: rotate(230deg) translate(270px, 120px);
    animation: penAnimation 3s 0.6s forwards;
}

.penContainer.picking {
    opacity: 0;
}

.uploadingContainer {
    position: absolute;
    top: 800px;
    visibility: hidden;
    transition: visibility 0s 1s;
}

.uploadingContainer div {
    color: #353535;
    text-align: center;
    font-family: Dela Gothic One;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0;
    transition: opacity 1s 2s;
}

.uploadingContainer.uploading {
    visibility: visible;
}
.uploadingContainer.uploading div {
    opacity: 1;
}

.uploadingContainer.showingCollection {
    transition: opacity 0s, visibility 0s 0.3s;
    opacity: 0;
}

.uploadingContainer.showingCollection {
    transition: opacity 0.3s, visibility 0s 0.3s;
    visibility: hidden;
}



/* @keyframes loadingPen {
    0% {
        transform: translate(90px, -90px) rotate(-20deg);
    }
    50% {
        transform: translate(90px, -150px) rotate(-10deg);
    }
    100% {
        transform: translate(90px, -90px) rotate(-20deg);
    }
} */
/* 
.penContainer.imageCreated .penBody {
    transform: rotate(230deg) translate(270px, -200px) scale(1.5);
}

.penContainer.imageCreated .penBody {
    transform: rotate(230deg) translate(270px, -500px) scale(1);
} */

@keyframes penAnimation {

    25% {
        transform: rotate(230deg) translate(270px, -200px) scale(1.5);
    }
    50% {
        transform: rotate(230deg) translate(270px, -500px) scale(1);
    }
    100% {
        transform: rotate(180deg) translate(270px, -150px);
    }
}


.ai-image-generator {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    /* margin-top: 80px; */
    /* margin-bottom: 20px; */
    gap: 30px;
    /* background-color: #FFEDD0; */
}

.image {
    background-color: red;
    margin: 10px;
    top: 0px;
    left: -200px;
    width: 400px;
    mix-blend-mode: multiply;
    filter: contrast(1.2);
    /* position: relative; */
    position: absolute;
    z-index: 2;
}

#imageid {
    position: absolute;
    width: 300px;
    padding: 30px;
    transition: clip-path 1.8s 0.65s;
    clip-path: inset(0% 0px 0% 0px);
    /* background: blue; */
}

.image.not-loaded #imageid{
    /* display: none; */
    clip-path: inset(0% 0px 100% 0px);
}

.shapeBox {
    /* background: red; */
    /* width: 400px; */
    /* display: none; */
    position: absolute;
    top: -30px;
}

.shapeBox img {
     width: 460px;;
}

.header {
    font-size: 36px;
    font-weight: 500;
    padding-bottom: 30px;
    width: 600px;
    position: absolute;
    top: 100px;
    text-align: center;
    font-family: 'Dela Gothic One', sans-serif;
    pointer-events: none;
}

.header.imageCreated {
    pointer-events: all;
}

.header.picking {
    pointer-events: none;
}

.header span {
    /* color: red; */
    /* font-weight: 600; */
}



.search-box:hover {
    transform: scale(1.1);
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.15);
}

.search-box.loading {
    transform: scale(1.15) translateY(-20px);
    box-shadow: 0px 15px 0px 0px rgba(0, 0, 0, 0.05);
    transition: transform 0.8s, box-shadow 0.8s;
}

.search-box.imageCreated {
    transform: scale(1.1);
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.15);
}

.search-box {
    position: absolute;
    top: 200px;
    display: flex;
    width: 380px;
    height: 380px;
    justify-content: space-around;
    align-items: center;
    border: 10px solid #353535;
    background: #DEF009;
    text-align: center;
    box-shadow: 7px 15px 0px 0px rgba(0, 0, 0, 0.25);
    transform: rotate(2deg);
    margin-bottom: 60px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.search-box.picking {
    border: 10px solid rgba(0, 0, 0, 0);
    background: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.search-box.uploading {
    transform: scale(1.3) translateY(-50px);
    transition: transform 1s 0.6s;

}

.search-box.uploading .shapeBox img {
    /* animation: uploadingShadow 1s forwards; */
    filter: drop-shadow(0px 70px 20px rgba(0, 0, 0, 0.15));
    transition: filter 1s 0.6s;
}

.search-box.showingCollection {
    /* width: 300px; */
    /* transform: scale(0.52) translate(-489px, -15px); */
    animation: addStickerToCollection 1s forwards cubic-bezier(1,-0.35,.58,1.08);
    z-index: 3;
}

.search-box.didSkip {
    display: none;
}

.search-box.showingCollection .shapeBox img {
    filter: drop-shadow(0px 70px 20px rgba(0, 0, 0, 0));
    transition: filter 0.2s;
}

@keyframes addStickerToCollection {
    30% {
        /* transform: scale(1.3) translateY(-50px) rotate(30deg); */
    }
    100% {
        transform: scale(0.52) translate(-489px, 70px) rotate(3deg);
    }   
}

.search-input {
    width: 80%;
    height: 90%;
    background: transparent;
    border: none;
    outline: none;
    color: #353535;
    /* background-color: red; */
    margin: 10%;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    font-family: 'Public Sans', sans-serif;
    display: inline;
    vertical-align: middle;
    overflow: auto;
    transition: clip-path 1.8s 0.65s;
    clip-path: inset(0% 0px 0% 0px);
    resize: none;
    pointer-events: all;
}

.search-input.not-loaded {
    clip-path: inset(100% 0% 0px 0px);
}

.search-input::placeholder {
    color: #353535;
    opacity: 40%;
    z-index: -10;
    padding-top: 50%;
    transform: translateY(-30%);
    transition: opacity 0.8s;
}
.search-input.aboutToChange::placeholder {
    opacity: 0%;
}

.generate-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 300px; */
    height: 85px;
    padding: 0px 34px; 
    font-size: 32px;
    border-radius: 50px;
    background: #353535;
    color: white;
    font-family: 'Public Sans', sans-serif;
    font-weight: 700;
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear, transform 0.3s, box-shadow 0.3s;
}

.invert .generate-btn {
    background: none;
    color: #353535;
}


.customizationButtonBox {
    position: absolute;
    top: 360px;
    /* background: red; */
    width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.customizationButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    width: 85px;
    border-radius: 50px;
    background: #353535;
    cursor: pointer;
    transition: opacity 0.1s linear, transform 0.3s, box-shadow 0.3s;
}
.customizationButton.left {
    transform: rotate(180deg);
}

.customizationButton.right {}

.generate-btn:hover, .customizationButton:hover, .shareButton:hover, .closeButton:hover {
    box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(-10px);
    opacity: 0.7;
    transition: opacity 0.1s linear, transform 0.3s, box-shadow 0.3s;
}

.generate-btn.loading {
    opacity: 0;
}

.generate-btn.imageCreated {
    opacity: 1;
}

.generate-btn.uploading {
    visibility: hidden;
    opacity: 0;
    transition: height 0.4s, visibility 0s 1s, opacity 0.8s linear;
}

.generate-btn.didSkip {
    transition: opacity 0.2s linear, transform 0.3s, box-shadow 0.3s;
}

.header.loading {
    /* visibility: hidden; */
    /* opacity: 0; */
    transform: translateY(-40px);
    transition: height 0.4s, visibility 0s 1s, opacity 0.8s linear, transform 0.9s; 
}

.header.didSkip {
    display: none;
}

.header span {
    opacity: 1;
    transition: opacity 1s;
}

.header span.aboutToChange {
    opacity: 0;
}

.header.animateLoading {
    opacity: 0;
}

.header.imageCreated {
    transform: none;
    opacity: 1;
}

.header.uploading {
    opacity: 0;
}

.invert .generate-btn:hover {
    box-shadow: none;
}

.customizationButton.left:hover {
    transform: translateY(-10px) rotate(180deg);
    box-shadow: 0px -10px 24px 0px rgba(0, 0, 0, 0.25);
}

.customizationButtonBox {
    display: none;
}

.customizationButtonBox.picking {
    display: flex;
}

.customizationButtonBox.uploading {
    opacity: 0;
}

.fadeAway {
    visibility: hidden;
    opacity: 0;
    transition: height 0.4s, visibility 0s 1s, opacity 0.8s linear;
}

.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: height 0.4s 0.1s, visibility 0s 0s, opacity 0.8s linear;
}

.header.fadeIn {
    transition: height 0.4s 0s, visibility 0s 0s, opacity 0.8s linear;
}

.generate-btn.fadeAway, .header.fadeAway {
    height: 0;
    opacity: 0;
}

@keyframes fadeOut {
    0% {opacity: 1; }
    99% {opacity: 0; }
    100% {display: none;}    
}

.lowerButtonBox {
    position: absolute;
    top: 680px;
}

.lowerButtonBox.header.didSkip, .lowerButtonBox.header.didSkip .generate-btn {
    transition: none;
    display: none;
}

.footer {
    background-color: #FEA5DD;
    margin-top: 100px;
    /* height: 100px; */
    width: 100%;
}

.footer .title {
    color: #353535;
    text-align: center;
    font-family: Dela Gothic One;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.titleContainer {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 70%;
    display: block;
    flex-direction: column;
    align-items: center;
    gap: 29px;
}

.footer .socialButtons {
    margin: auto;
    margin-top: 20px;
    display: flex;
    width: 515px;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
}

.socialButtons img {
    transition: opacity 0.2s;
}
.socialButtons img:hover {
    opacity: 0.5;
}

.footer .teamPhoto {
    /* margin: 0 10px; */
    width: 100%;
    margin-bottom: -10px;
}

.stickerContainer {
    position: relative;
    /* top: 300px; */
    width: 800px;
    display: table;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    /* background: blue; */
}

.stickerContainer.showingCollection {
    visibility: visible;
    transition: opacity 0.5s 0.5s;
    opacity: 1;
}

.stickerContainer.showingCollection {
    transition: opacity 0.5s 1s;
}

.stickerContainer.didSkip .sticker.placeholder {
    display: none;
}

.sticker {
    position: relative;
    width: 33.3%;
    float: left;
}


.sticker-footer {
    height: 100px;
    width: 100%;
    background: pink;
    /* bottom: 0px; */
}

.stickerImage { 
    mix-blend-mode: multiply;
    filter: contrast(1.2);
    position: absolute;
    z-index: 0;
    width: 50%;
    padding: 29%;
    /* background: red; */
    /* top: 80px; */
    /* left: 100px; */
    /* transform: translate(50%, 50%); */
}

.sticker.placeholder {
    /* width: 10px; */
    background-color: red;
    /* height: 10px; */
    visibility: hidden;
}

.stickerBackground {
    padding: 10%;
    width: 90%;
    height: 90%;
    z-index: -1;
    /* background-color: blue; */
    position: relative;
}

.stickerBackground.shadow {
    position: absolute;
    z-index: -3;
    top: -10px;
    opacity: 0.2;
    filter: blur(4px);
    transition: filter 0.8s cubic-bezier(.63,-0.1,.18,1.1), opacity 0.8s cubic-bezier(.63,-0.1,.18,1.1);
}

.sticker.down {
    transform: translateY(20%);
}

.display-none {
    display: none;
}

      /* <div className="originalPromptBox">
                            <div className="start">My wish for 2024 is to...</div>
                            <div className="userPrompt">{url.prompt}</div>
                        </div> */

.originalPromptBox {
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 15px;
    /* background-color: red; */
    /* opacity: 0.4; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.originalPromptBox .start {
    align-self: stretch;
    text-align: center;
    /* background-color: orange;    */
    color: #353535;
    text-align: center;
    font-family: Dela Gothic One;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.originalPromptBox .userPrompt {
    align-self: stretch;
    text-align: center;
    /* background-color: green; */
    color: #353535;
    text-align: center;
    font-family: Public Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 20px;
}

.stickerContainer .stickerLiftContainer {
    /* background-color: red; */
    transition: transform 0.8s cubic-bezier(.63,-0.1,.18,1.1);
    pointer-events: none;

}

.stickerLiftTarget {
    /* background: red; */
    /* opacity: 0.3; */
}

.stickerContainer.showingCollection .stickerLiftTarget {
    transition: pointer-events 10s 14s;
    pointer-events: all;
}

.stickerContainer.showingCollection .stickerLiftTarget:hover .stickerLiftContainer {
    /* background: blue; */
    transform: translateY(-240px) rotate(10deg) scale(1.4);
}
.stickerLiftTarget:hover .stickerBackground.shadow {
    opacity: 0.1;
    filter: blur(24px);
}

.sticker.local {
    /* visibility: hidden; */
    /* z-index: 10; */
}

.stickerContainer.showingCollection .sticker.local {

}

.stickerLiftContainer .shapeBox {
    left: -40px;
}

.search-box .stickerLiftTarget {
    height: 380px;
}

.search-box .stickerLiftContainer {
    height: 100%;
}
.search-box .shadow, 
.search-box .originalPromptBox {
    visibility: hidden;
}
.search-box.showingCollection .shadow, 
.search-box.showingCollection .originalPromptBox {
    visibility: visible;
}

.search-box.showingCollection .stickerLiftTarget .image, .stickerLiftTarget .shapeBox {
    transition: transform 0.8s cubic-bezier(.63,-0.1,.18,1.1);
}
.search-box.showingCollection .stickerLiftTarget:hover .image, .search-box.showingCollection .stickerLiftTarget:hover .shapeBox {
    /* top: -60px; */
    transform: translateY(-400px);
}

.search-box.showingCollection .stickerLiftTarget:hover .stickerLiftContainer {
    transform: none;
    /* background-color: purple; */
}

.search-box .stickerBackground.shadow {
    /* z-index: 20; */
    transform: translate(-200px, 17px) scale(1.3);
}

.search-box .originalPromptBox {
    /* background: green; */
    transform: translate(-10px, 30px) rotate(-3deg) scale(0.9);
}

.search-box .start {
    font-size: 20px
}

.search-box .userPrompt {
    font-size: 42px;
}



/* <div className={showCollection?"collectionText":"collectionText noDisplay"}>
<div className="collectionHeader">
    Thank you for sharing your wish!
</div>
<div className="collectionBody">
    Get inspired below by what our friends are hoping for!
</div> */

.collectionText.noDisplay {
    visibility: hidden;
    opacity: 0;
}

.collectionText.noDisplay .generate-btn {
    display: none;
}

.collectionText {
    /* position: absolute; */
    margin-top: 80px;
    transition: opacity 1s 1s;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.collectionHeader {
    opacity: 1;
    color: #353535;
    text-align: center;
    font-family: Dela Gothic One;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.collectionBody {
    color: #353535;
    text-align: center;
    font-family: Public Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.collectionText .generate-btn {
    display: inline-flex;
    padding: 10px 24px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: auto;
    margin-top: 20px;
    /* transition: none; */
}

.wandContainer {
    position: absolute;
    width: 350px;
    height: 67px;
    top: 200px;
    transform: translateX(300px) rotate(-40deg);
    animation: moveWand 0.4s;
    z-index: 3;
}
.wandTip {
    position: absolute;
    width: 80px;
    height: 29px;
    border-radius: 89px 0px 0px 89px;
    border: 19px solid #353535;
    background: #F86657;
}

.wandContainer.color0 .wandTip {background: #DEF009; }
.wandContainer.color1 .wandTip {background: #F86657;}
.wandContainer.color2 .wandTip {background: #3AC999; }
.wandContainer.color3 .wandTip {background: #FEA6D9;}

.wandContainer.color1 {animation: moveWand2 0.4s;}
.wandContainer.color3 {animation: moveWand2 0.4s;}

@keyframes moveWand {
    50% {
        transform: translate(300px, 20px) rotate(-50deg);
    }
}

@keyframes moveWand2 {
    50% {
        transform: translate(300px, 20px) rotate(-50deg);
    }
}

.wandBody {
    border-radius: 89px;
    height: 29px;
    border: 19px solid #353535;
    background: #FFF;
}

.scissorsContainer {
    position: absolute;
    top: 150px;
    z-index: 20;
    transform: translateX(-400px) rotate(60deg);
}

.scissors1 {
    /* animation: moveScissor 0.4s; */
}
.scissors2 img {
    transform: scaleY(-1) translate(0px, 60px) rotate(50deg) ;
}

.scissorsContainer.shape1 .scissors1{
    animation: moveScissor1 0.4s;
} 
.scissorsContainer.shape2 .scissors1{
    animation: moveScissor2 0.4s;
} 
.scissorsContainer.shape0 .scissors1{
    animation: moveScissor3 0.4s;
} 

.scissorsContainer.shape1 .scissors2{
    animation: moveScissor1-alt 0.4s;
} 
.scissorsContainer.shape2 .scissors2{
    animation: moveScissor2-alt 0.4s;
} 
.scissorsContainer.shape0 .scissors2{
    animation: moveScissor3-alt 0.4s;
} 

@keyframes moveScissor1 {
    50% {
        transform: rotate(-20deg);
    }
}
@keyframes moveScissor2 {
    50% {
        transform: rotate(-20deg);
    }
}
@keyframes moveScissor3 {
    50% {
        transform: rotate(-20deg);
    }
}

@keyframes moveScissor1-alt {
    50% {
        transform: rotate(15deg);
    }
}
@keyframes moveScissor2-alt {
    50% {
        transform: rotate(15deg);
    }
}
@keyframes moveScissor3-alt {
    50% {
        transform: rotate(15deg);
    }
}

.disclaimer {
    /* width: 100%; */
    text-align: center;
    /* background-color: red; */
    color: #000;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    }

.disclaimer {
    display: none;
}

.disclaimer.imageCreated {
    display: block;
}

.disclaimer.picking {
    display: none;
}

.legalLinks {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #FFEDD0;
    padding: 10px 0px 10px;
    width: 100%;
}

.copywright {
    padding-left: 20px;
}

.legalCollection {
    display: flex;
    align-items: flex-start;
    gap: 31px;
    /* background-color: green; */
    padding-right: 20px;
}

.legalCollection a {
    color: black;
    text-decoration: none;
}
.legalCollection div:hover {
    text-decoration: underline;
    opacity: 0.5;
    cursor: pointer;
}

.footer .borderShape {
    position: absolute;
    left: 20px;
    width: 200px;
    transform: translateY(-100px) rotate(-30deg) ;
    transition: transform 5s;
}

.footer .borderShape:hover {
    transform: translateY(-100px) rotate(9000deg) ;
    transition: transform 20s;
}

/* <div className="introAnimationContainer">
                <div className="introHeader">TO ANOTHER YEAR FILLED WITH</div>
                <div className="introBigText">PLAY</div>
                <div className="introBody">Happy holidays from the IDEO Play Lab! Thank you for your partnership with us this year!
                In the spirit of making magic together, we invite you to play along with us through this interactive card. 

                Follow the prompts to cast your wishes for 2024 (and for a surprise at the end)!
                </div>
                <div className="generate-btn">jump in</div>
            </div> */


.imagePopUp {
    display: block;
    visibility: hidden;
    background-color: white;
    position: absolute;
    width: 600px;
    height: 831px;
    z-index: 1000;
    top: 100px;
    border-radius: 33px;
    opacity: 0;
    transform: translateY(100px) scale(1.2);
    transition: opacity 0.2s linear, transform 0.5s, visibility 0s 0.5s;
}


.imagePopUp.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.2s linear, transform 0.5s, visibility 0s;
    }
    
    .canvasContainer {
    padding: 40px;
    height: 631px;
    /* background-color: red; */
    }
    
    .canvasContainer canvas {
    /* background: #FFEDD0; */
    transform: scale(0.335);
    transform-origin: top left;
    border-radius: 60px; 
    border: 20px solid #353535;
    cursor: context-menu;
    }
    
    .canvasContainer .shareButtonContainer {
    /* background-color: blue; */
    display: flex;
    width: 518px;
    justify-content: center;
    align-items: flex-start;
    gap: 22px;
    position: absolute;
    top: 700px;
    
    }
    .canvasContainer .shareButton {
        cursor: pointer;
    display: flex;
    width: 86px;
    height: 86px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 1000px;
    background: #353535;
    transition: opacity 0.1s linear, transform 0.3s, box-shadow 0.3s;
    }

    .imagePopupOverlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    visibility: hidden;
    opacity: 0;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7); /* Black background with opacity */
    z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    transition: opacity 0.4s, visibility 0s 0.4s;
}

.imagePopupOverlay.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s, visibility 0s;
    }

    .canvasTitle {
    position: absolute;
    top: 580px;
    color: #353535;
text-align: center;
font-family: "Public Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 518px;
height: 27px;
flex-shrink: 0;
/* background-color: red; */
    }

    .canvasBody {
        position: absolute;
        top: 645px;
        color: #353535;
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 518px;
    height: 27px;
    flex-shrink: 0;
    /* background-color: red; */
        }

    .closeButton {
    position: absolute;
    top: 0px;
    left: -120px;
    display: flex;
    width: 87px;
    height: 87px;
    padding: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 1000px;
    cursor: pointer;
background: #FFF;
    /* opacity: 0; */
transition: opacity 0.1s linear, transform 0.3s, box-shadow 0.3s, left 0.4s 0.8s;
    }

    .imagePopUp.show .closeButton {
    /* left: -120px;     */
    /* opacity: 1; */
    }
            

.loadMoreButtonContainer {
    margin-top: 60px;
    position: relative;
    /* width: 100%; */
    /* background-color: red; */
}


@media screen and (max-width: 960px) {


    .borderShapesContainer.intro .borderShape5 {
        /* display: none !important; */
        transform: translate(-100px, 0px)  scale(0.7);

    }

    .borderShapesContainer.intro .borderShape6 {
        /* display: none !important; */
        transform: translate(120px, -10px) scale(0.7);
    }
        
}

@media screen and (max-width: 430px) {
    body {
        /* opacity: 0.3; */
        zoom: 0.7;
    }

    html, body {
        position: relative;
        height: 100%;
    }

    .intro .introHeader {
        /* width: 20px; */
        font-size: 32px;
        width: 300px;
        margin-left: 250px;
        /* background-color: red; */
        /* left: 600px; */
    }

    .bigTextContainer {
        margin-top: -40px;
        width: 400px;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 30px 10px;
        margin-left: 40px;

    }

    .introBigText {
        font-size: 200px;
    }

    .introBigText.l {
        margin-left: 20px;
    }

    .penContainer.intro {
        transform: translate(190px, 90px) rotate(-90deg) scale(0.6);
    }

    .introBody {
        width: 80%;
        margin-left: 200px;
        font-size: 18px;
        margin-top: 110px;
    }

    .introAnimationContainer.intro .buttonContainer {
        /* top: auto; */
        top: 800px;
        width: 80%;
    }

    .introAnimationContainer.intro .buttonContainer .generate-btn {
        padding: 20px;
    }


    .borderShape1 {
        opacity: 0;
        top: auto;
        bottom: -557px !important;
        right: -420px;
        transition: opacity 1s;
    }
    .borderShape2 {
        display: none !important;
        top: -58px;
        right: -98px;
        transition: opacity 1s;
    }
    .borderShape6 {
        /* display: none !important; */
        top: -35px;
        right: 103px;
        transition: opacity 1s;
    }
    .borderShape3 {
        display: none !important;
        top: -80px;
        left: -188px;
        transition: opacity 1s;
    }
    .borderShape4 {
        display: none !important;
        top: 766px;
        left: -209px;
    }
    .borderShape5 {
        display: none !important;
        top: 366px;
        left: -60px;
    }

    .start .penContainer {
        /* opacity: 0; */
        transform: translate(220px) rotate(-50deg);
    }

    .start .borderShape6 {
        opacity: 0;
    }

    .start .borderShape1 {
        opacity: 1;
    }

    .header {
        width: 300px;
        font-size: 32px;
        top: 90px;
    }

    .search-box {
        top: 220px
    }

    .lowerButtonBox {
        top: 700px;
        /* bottom: 50px; */
    }

    .picking .header {
        width: 400px;
    }

    .picking .lowerButtonBox {
        top: 800px
    }

    .picking .customizationButtonBox {
        /* top: auto; */
        top: 680px;
        width: 80%;
    }

    .customizationButton {
        width: 200px;
    }

    .scissorsContainer {
        top: 0px;
        transform: translate(-200px, 200px) rotate(60deg) scale(0.5);
    }

    .customizationButton.left:hover {
        opacity: 1;
        transform: rotate(180deg); 
    }
    .customizationButton:hover {
        opacity: 1;
        transform: none;
    }

    .wandContainer {

    }

    .stickerContainer {
        width: 100%;
        padding: 0px 24px;
    }

    .sticker {
        width: 50%;
        /* background-color: red; */
    }

    .sticker.down {
        transform: none;
    }

    .search-box.showingCollection {
        animation: addStickerToCollection-mobile 1s forwards cubic-bezier(1,-0.35,.58,1.08);
    }
    
    @keyframes addStickerToCollection-mobile {
        30% {
            /* transform: scale(1.3) translateY(-50px) rotate(30deg); */
        }
        100% {
            transform: scale(0.57) translate(-230px, 158px) rotate(3deg);
        }   
    }

    .footer .borderShape {
        display: none;
    }

        
    .titleContainer {

    }

    .collectionBody {
        width: 80%;
    }
    
    .footer .socialButtons {
        width: 100%;
    }

    .footer .teamPhoto {
        /* width: 200%;
        transform: translate(-25%); */
    }


    .penContainer.start {
        transform: translate(220px, 00px) rotate(-50deg) scale(1);
        transition: transform 1s;
    }


    .penContainer.drawingLoop {
        transform: translate(120px, -40px) rotate(-40deg) scale(1);
    }

    .penContainer.animateLoading .penBody {
        animation: penAnimation2 3s 0.6s forwards;
    }
    .penContainer.animateLoading {
        transform: translate(220px, 00px) rotate(-50deg) scale(1);
    }

    @keyframes penAnimation2 {
    
        25% {
            transform: rotate(230deg) translate(270px, -200px) scale(1.5);
        }
        50% {
            transform: rotate(230deg) translate(270px, -500px) scale(0.8);
        }
        100% {
            transform: rotate(160deg) translate(330px, -40px) scale(0.8);
        }
    }

    .borderShapesContainer.start {
        transition: z-index 0s 0s;
    }

    .stickerContainer.showingCollection .stickerLiftContainer {
        transform: scale(1.01);
    }

    .imagePopUp {

    }

    .imagePopUp.show {
        transform: translateY(0px) scale(0.9);
    }

    .imagePopUp .closeButton {
        left: calc(50% - 50px);
        top: 850px;
    }
}

@media screen and (max-width: 375px) {
    body {
        /* opacity: 0.3; */
        zoom: 0.6;
    }

}

